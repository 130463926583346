import React from 'react'
import './photographieForm.css'
import Photographie from '../../../../images/photographie.PNG'
import { Grid } from '@mui/material'
const PhotographieForm = (args) => {
  return (
    <Grid align="center" container>
      <Grid align="center" item xs={12} sm={12} md={12} lg={12} xl={12} >
        <h1 style={{ margin: "2%" }}>The registration are now over stay tuned with us on our social media for more workshops and meetings </h1>
        {/* <ParticipantEnglishWorkshopFrom data={data} setData={setData} handelDataChange={handelDataChange} handleDataLevelChange={handleDataLevelChange} /> */}
      </Grid>
      <Grid align="center" item xs={12} sm={12} md={12} lg={12} xl={12} >
        <img loading="lazy" style={{ minWidth: "350px" }} width="90%" height="auto" src={Photographie} alt="registration_img" />
      </Grid>
    </Grid>
    // <div className='Parent'>
    //   <h1>The registration are now over stay tuned with us on our social media for more workshops and meetings </h1>
    //   <img className='photoEdit' src={Photographie} />
    //   <div className='formParent'>
    //     <form className='form needs-validation'>
    //       <div className="form-group">
    //         <h2 style={{ margin: "2%" }} for="exampleInputEmail1">Personal Info</h2>
    //         <input style={{ width: "90%", margin: "2%" }} onChange={args.handlefullNameChange} type="text" className="form-control" id="InputName" aria-describedby="nameHelp" placeholder=" Full Name" required />
    //         <input style={{ width: "90%", margin: "2%" }} onChange={args.handleEmailChange} type="email" className="form-control" id="InputEmail" aria-describedby="emailHelp" placeholder="Email" required />
    //         <input style={{ width: "90%", margin: "2%" }} onChange={args.handlePhoneChange} type="number" className="form-control" id="InputPhone" aria-describedby="phoneHelp" placeholder="Phone number" required />
    //         <h2> Are you student ? </h2>
    //         <input className="form-check-input" type="radio" name="student_question" id="student_question" value="No" onChange={args.handlestudentChange} />
    //         <label classNmae="form-check-label" for="student_question">
    //           <p>No</p>
    //         </label>
    //         <br />
    //         <input className="form-check-input" type="radio" name="student_question" id="student_question2" value="Yes" onChange={args.handlestudentChange} />
    //         <label class="form-check-label" for="student_question2">
    //           <p>Yes</p>
    //         </label>
    //         <h2>What is Your Level ? </h2>
    //         <input className="form-check-input" type="radio" name="level" id="Photographer" value="Photographer" onChange={args.handlelevelChange} />
    //         <label class="form-check-label" for="Photographer">
    //           <p>Photographer</p>
    //         </label>
    //         <br />
    //         <input className="form-check-input" type="radio" name="level" id="Beginner" value="Beginner" onChange={args.handlelevelChange} />
    //         <label class="form-check-label" for="Beginner">
    //           <p>Beginner</p>
    //         </label>
    //         <br />
    //         <input className="form-check-input" type="radio" name="level" id="amateur" value="amateur" onChange={args.handlelevelChange} />
    //         <label class="form-check-label" for="amateur">
    //           <p>amateur</p>
    //         </label>
    //         <br />
    //         <h2> Do you own a camera? </h2>
    //         <input className="form-check-input" type="radio" name="camera_question" id="iDontHaveCamera" value="No" onChange={args.handlecameraChange} />
    //         <label classNmae="form-check-label" for="iDontHaveCamera" >
    //           <p>No</p>
    //         </label>
    //         <br />
    //         <input className="form-check-input" type="radio" name="camera_question" id="iHaveCamera" value="Yes" onChange={args.handlecameraChange} />
    //         <label class="form-check-label" for="iHaveCamera">
    //           <p>Yes</p>
    //         </label>
    //         <br />
    //         <small style={{ width: "90%", margin: "2%" }} id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
    //       </div>
    //       <button style={{ margin: "2%" }} type="submit" onClick={args.onSubmitEmail} className="btn btn-primary">Submit</button>
    //     </form>
    //   </div>
    // </div>
  )
}

export default PhotographieForm;
