import React, { useEffect, useState } from 'react';
import PhotographieForm from './component/form/photographieForm'
import firebase_database from '../firebase.js'
import { setDoc, doc } from "@firebase/firestore"
const Photographie = () => {
    const [email, setemail] = useState("")
    const [phone, setphone] = useState("")
    const [fullName, setfullName] = useState("")
    const [level, setlevel] = useState("")
    const [student, setstudent] = useState("")
    const [camera, setcamera] = useState("")
    const sendDataToTheBackend = () => {
        const participantData = {
          fullName: fullName,
          email: email,
          phone: phone,
          level : level , 
          student : student , 
          camera: camera, 
        }
        setDoc(doc(firebase_database, "photographie_forms", phone), participantData)
      }
      const on_set_show_email = (e) => {
        try {
          e.preventDefault()
          if (fullName.length <= 5 || phone.length !== 10) {
            alert("vous devez renseigner des champs valide , vous devez rensiegner votre nom et prenom , le numeor de telephone dois consiste de 10 chiffres")
            return
          }
          sendDataToTheBackend()
          alert("your registration was successfull")
        } catch (error) {
          alert("unknown error occured " + error)
        }
      }
      const handlefullNameChange = (e) => {
        setfullName(e.target.value)
      }
      const handlePhoneChange = (e) => {
        setphone(e.target.value)
      }
      const handleEmailChange = (e) => {
        setemail(e.target.value)
      }
      const handlelevelChange = (e) => {
        setlevel(e.target.value)
      }
      const handlestudentChange = (e) => {
        setstudent(e.target.value)
      }
      const handlecameraChange = (e) => {
        setcamera(e.target.value)
      }
    return (
        <div>
            <PhotographieForm handlefullNameChange={handlefullNameChange} handleEmailChange={handleEmailChange} handlePhoneChange={handlePhoneChange} handlelevelChange={handlelevelChange} handlestudentChange={handlestudentChange} handlecameraChange={handlecameraChange}   onSubmitEmail={on_set_show_email}/>
        </div>
    )
}
export default Photographie
