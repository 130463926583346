import React, { useEffect, useState } from 'react'
// import ActualityElement from '../ActualityComponent/ActualityElement'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import firebase_database from '../../firebase'
import { collection, getDocs, query, doc, getDoc, onSnapshot } from "@firebase/firestore"
import { Grid } from '@mui/material';
import './Actuality.css'
import LogoActuality from '../../../svg/undraw_news.svg'
const CircularIndeterminate = () => (
    <Box className='CircularIndeterminate' sx={{ width: '100%' }}>
        <LinearProgress />
    </Box>
)

const actualityFirebaseCollectionReference = collection(firebase_database, 'actuality')

const Actuality = () => {
    const [actualityList, setActualityList] = useState([])
    useEffect(() => {
        const getActualities = async () => {
            const firebaseActualityList = await getDocs(actualityFirebaseCollectionReference)
            setActualityList(firebaseActualityList.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }
        getActualities()
    }, [])
    return (
        <div>
            {!actualityList.length > 0 && <CircularIndeterminate />}
                {actualityList && actualityList.map((element, index) =>
                    <div className='publications'>
                    { /*<ActualityElement key={index} image={element.image} description={element.description}  date={element.date} detail={element.detail} /> */}
                    <iframe src={element.src} width={500} height={665} style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder={0} allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" />
                    </div>
                )
                }
        </div>
        
    )
}

export default Actuality