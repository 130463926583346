import { Grid } from '@mui/material'
import React, { useState } from 'react'
import SportConferenceImage from '../../../images/sport_conference.png'
import ParticipantSportConferenceForm from './ParticipantSportConferenceForm'

const SportConference = () => {
    const [data, setData] = useState({
        name: "",
        telephone_number: "",
        email: "",
        student: false,
        usto_student: false,
        field: "",
        year: "",
        // english_level: "",
        comment: "",
    })

    const handleDataLevelChange = (event) => {
        console.log(data);
        setData({ ...data, english_level: event.value })
    }
    

    const handelDataChange = (event) => {
        if (!event.target.name.includes("student"))
            setData({ ...data, [event.target.name]: event.target.value })
        else {
            setData({ ...data, [event.target.name]: event.target.checked })
        }
    }

    return (
        <Grid align="center" container>
            <Grid align="center" item xs={12} sm={12} md={12} lg={12} xl={12} >
                {/* <h1 style={{margin:"2%"}}>The registration are now over stay tuned with us on our social media for more workshops and meetings </h1> */}
                <ParticipantSportConferenceForm data={data} setData={setData} handelDataChange={handelDataChange} handleDataLevelChange={handleDataLevelChange} />
            </Grid>
            <Grid align="center" item xs={12} sm={12} md={12} lg={12} xl={12} >
                <img loading="lazy" style={{ minWidth: "350px" }} width="90%" height="auto" src={SportConferenceImage} alt="registration_img" />
            </Grid>
        </Grid>
    )
}

export default SportConference
