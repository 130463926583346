import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import React from 'react'
import Select from 'react-select';
import { setDoc, doc } from "@firebase/firestore"
import firebase_database from '../component/firebase';
import { useNavigate } from "react-router-dom";

const emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

const options = [
    { value: 'No knowledge', label: 'No knowledge' },
    { value: 'Elementary level', label: 'Elementary level' },
    { value: 'Low intermediate', label: 'Low intermediate' },
    { value: 'High intermediate', label: 'High intermediate' },
    { value: 'Advanced', label: 'Advanced' },
    { value: 'Proficient', label: 'Proficient' },
];

const customStyles = {
    container: provided => ({
        ...provided,
        width: "40%",
        margin: "2%"
    })
};

const SportConferenceFrom = ({ data, handelDataChange, handleDataLevelChange }) => {
    // const { name, telephone_number, email, student, usto_student, field, year, english_level, comment } = data
    const { name, telephone_number, email, student, usto_student, field, year, comment } = data
    const navigate = useNavigate();
    const handleSendData = async (event) => {
        if (name.length < 6) {
            alert("please specify your full name")
            return
        }
        if (telephone_number.length !== 10){
            alert("please specify a valid phone number")
            return
        }
        if (!emailRegex.test(email)) {
            alert("please specify a valid email")
            return
        }
        if (field.length === 0) {
            alert("please specify your speciality")
            return
        }
        if (student && year.length === 0) {
            alert("please specify your year of study")
            return
        }
        // if (!['No knowledge','Elementary level','Low intermediate','High intermediate','Advanced','Proficient'].includes(english_level)) {
        //     alert("please specify your level")
        //     return
        // }
        try {
            console.log(data);
            await setDoc(doc(firebase_database, "sport_conference", telephone_number), data)
            alert('You registration was succefull , Thank you !')
            navigate("/");
        } catch (error) {
            console.log(error);
            alert('Unkown error has happen please retry later : ', error.message || error)
        }
    }

    return (
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2 style={{ textAlign: "center", margin: "1%" }}>
                Sport Conference Registration
            </h2>
            <TextField required name="name" value={name} type="text" label="Name" variant="outlined" style={{ margin: "2%", borderColor: "red" }} onChange={handelDataChange} />
            <TextField required name="telephone_number" value={telephone_number} type="number" label="Phone Number" variant="outlined" style={{ margin: "2%" }} onChange={handelDataChange} />
            <TextField required name="email" value={email} type="email" label="Email" variant="outlined" style={{ margin: "2%" }} onChange={handelDataChange} />
            <FormControlLabel name="student" control={<Checkbox checked={student} onChange={handelDataChange} />} label="Are you Student ?" />
            {student && <FormControlLabel name="usto_student" control={<Checkbox checked={usto_student} onChange={handelDataChange} />} label="Student at USTO ?" />}
            <TextField required name="field" value={field} type="text" label="Your Speciality" variant="outlined" style={{ margin: "2%" }} onChange={handelDataChange} />
            {student &&<TextField required name="year" value={year} type="text" label="Year of study Ex : L1 , M2 , PHD" variant="outlined" style={{ margin: "2%" }} onChange={handelDataChange} />}
            {/* <h6 style={{ color: "grey", margin: "1%" }}>Your English Level</h6> */}
            {/* <Select
                styles={customStyles}
                defaultValue={english_level}
                onChange={handleDataLevelChange}
                options={options}
                label="test"
            /> */}
            <TextField required name="comment" value={comment} type="text" label="Comment" variant="outlined" style={{ margin: "2%" }} onChange={handelDataChange} />
            <button style={{ backgroundColor: "#ffc500", border: "none", padding: "1rem", color: "black", borderRadius: "100px", cursor: "pointer", margin: "2%" }} onClick={handleSendData}>Confirm</button>
        </Box >
    )
}

export default SportConferenceFrom
