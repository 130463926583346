import React from 'react'
import YoutubeEmbed from '../../Component/Youtube/YoutubeVideo'

const Events = () => {
    return (
        <div>
            <h1 style={{ textAlign: "center", margin: "2%" }}>In order to join us please visit our space directly </h1>
            <div className="youtube_video">
                <YoutubeEmbed x="400px" y="400px" />
            </div>
        </div>
    )
}

export default Events
